@import url(https://fonts.googleapis.com/css?family=Open+Sans|Special+Elite&display=swap);
@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
.listLocations .react-bs-table-container {
  /* Force table to not be like tables anymore */
  /* Hide table headers (but not display: none;, for accessibility) */ }
  .listLocations .react-bs-table-container table, .listLocations .react-bs-table-container thead, .listLocations .react-bs-table-container tbody, .listLocations .react-bs-table-container td, .listLocations .react-bs-table-container tr {
    display: block; }
  .listLocations .react-bs-table-container td {
    padding: 0; }
    .listLocations .react-bs-table-container td p {
      margin-bottom: 0; }
  .listLocations .react-bs-table-container thead tr {
    position: relative;
    top: 0;
    left: 0; }
  .listLocations .react-bs-table-container thead tr th.actions {
    position: absolute;
    top: -9999px;
    left: -9999px; }
  .listLocations .react-bs-table-container tr {
    border: 1px solid #ccc; }
  .listLocations .react-bs-table-container td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 0; }
    .listLocations .react-bs-table-container td:focus {
      outline: none; }
      .listLocations .react-bs-table-container td:focus p {
        border-bottom: 1px dotted grey; }
  .listLocations .react-bs-table-container .copy-place {
    padding: 0 1em 1em; }
  .listLocations .react-bs-table-container .copy-title {
    padding: 1em 1em 0; }

.allLocations .listLocations .react-bs-table-container td {
  padding-left: 0;
  padding-bottom: 0; }

.swiper-button-prev, .swiper-button-next {
  background-color: #9b4237;
  background-image: none;
  color: #fff;
  font-size: 2em;
  line-height: 1em;
  border-radius: 50%;
  width: 44px;
  border: 0;
  padding-left: 1px; }

.swiper-button-next {
  padding-left: 0.7rem; }

.swiper-pagination-bullet-active {
  background: #9b4237; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.impactIntro {
  font-size: 1.5rem;
  line-height: 1.9rem;
  padding: 0.5rem;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-animation-delay: 1s;
  -webkit-animation-name: fadein;
  -webkit-animation-duration: 5s;
  -webkit-animation-timing-function: linear;
  animation-delay: 1s;
  animation-name: fadein;
  animation-duration: 5s;
  animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.impactClick {
  font-size: 1.5rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  position: absolute;
  right: 1em;
  width: 9em;
  top: 4.5rem;
  margin: 0 auto;
  text-align: center;
  opacity: 0;
  -webkit-animation-delay: 7s;
  -webkit-animation-name: fadein;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  animation-delay: 7s;
  animation-name: fadein;
  animation-duration: 2s;
  animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }
  .impactClick svg {
    font-size: 4rem;
    display: block;
    text-align: center;
    margin: 0 auto -1.8rem; }

button:focus, a:focus {
  outline: 1px dotted white; }

.sidebar {
  padding-top: 60px;
  z-index: 999;
  position: absolute;
  min-width: 250px;
  max-width: 250px;
  color: #fff;
  margin-left: -250px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background-color: #181818;
  height: calc(100vh - 30px);
  overflow: hidden; }

.sidebarMenuInner {
  overflow-y: scroll;
  height: 100%; }

.sidebar.is-open {
  margin-left: 0;
  -webkit-transition: .5s;
  transition: .5s; }

#accordionGroup .Accordion-panel {
  color: #fff;
  padding: 1em 0;
  border-bottom: 1px solid #202020; }

.textCredits {
  animation: fadein 5s;
  -moz-animation: fadein 5s;
  -webkit-animation: fadein 5s;
  -o-animation: fadein 5s;
  -webkit-animation-delay: 5s;
          animation-delay: 5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.mobileBackground {
  background: url("/opening.jpg") no-repeat center center fixed;
  background-size: cover;
  background-size: cover;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%; }

.maxWidth {
  height: 100vh; }
  .maxWidth:after {
    content: "";
    background: url("/home-background.png");
    opacity: 0.6x;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1; }
  .maxWidth .mobileOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    height: 100%;
    Flex-direction: column;
    text-align: center; }
  .maxWidth .homeOverlay {
    position: absolute;
    bottom: 6%;
    left: 0;
    width: 100%;
    z-index: 1;
    animation: fadein 1s;
    -moz-animation: fadein 1s;
    -webkit-animation: fadein 1s;
    -o-animation: fadein 1s;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .maxWidth .homeLogo {
    animation: fadein 0.1s;
    -moz-animation: fadein 0.1s;
    -webkit-animation: fadein 0.1s;
    -o-animation: fadein 0.1s;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    opacity: 0;
    line-height: 120%;
    font-weight: 800;
    font-size: 2.3rem; }
  .maxWidth .blurb {
    font-size: 1.7rem;
    text-transform: none;
    text-transform: initial;
    z-index: 1;
    line-height: 1.7rem; }

.blurbTablet {
  font-size: 1.5rem;
  font-weight: normal;
  margin-top: 1em;
  position: absolute;
  bottom: 5em;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0;
  position: absolute;
  width: 93%;
  margin: 0 3%; }

.logo {
  font-weight: 800;
  font-size: 3rem;
  color: #BF9671;
  text-transform: uppercase; }

.aboutBlock {
  text-align: left;
  padding: 19% 5%;
  font-size: 1.5rem; }
  .aboutBlock h2 {
    text-align: center; }

.shameClick {
  position: relative;
  overflow: hidden; }
  .shameClick img {
    width: 98%;
    margin: 1%; }
  .shameClick img.shame-hover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0; }
  .shameClick:hover img {
    /* it's optional - for nicer transition effect */
    opacity: 0; }
  .shameClick:hover img.shame-hover {
    opacity: 1; }

.teacherImg {
  position: relative; }
  .teacherImg img {
    width: 100%; }
  .teacherImg img.banner-hover {
    position: absolute;
    top: 0;
    opacity: 0; }
  .teacherImg:hover img {
    /* it's optional - for nicer transition effect */
    opacity: 0; }
  .teacherImg:hover img.banner-hover {
    opacity: 1; }

.InvolvedBlock, .changeBlock {
  padding: 10% 7%;
  font-size: 1.5rem; }
  .InvolvedBlock .highlightCountry, .changeBlock .highlightCountry {
    margin: 1em;
    border-bottom: 1px solid white; }
  .InvolvedBlock h2, .changeBlock h2 {
    font-size: 2.8rem;
    color: #BF9671;
    font-weight: bold; }
  .InvolvedBlock h3, .changeBlock h3 {
    line-height: 2.3rem; }
  .InvolvedBlock a, .changeBlock a {
    color: white;
    display: flex; }
    .InvolvedBlock a:hover, .changeBlock a:hover {
      color: #BF9671;
      text-decoration: none; }
    .InvolvedBlock a svg, .changeBlock a svg {
      margin-right: 0.5em; }
  .InvolvedBlock .country, .changeBlock .country {
    text-transform: uppercase;
    border-bottom: 1px solid #fff; }
  .InvolvedBlock .sectionInvolved, .changeBlock .sectionInvolved {
    padding: 0 1em; }

.changeBlock {
  text-align: center;
  padding: 10% 3%; }
  .changeBlock .col-lg-5 {
    text-align: left;
    padding: 0 1.2rem; }

.resp-container {
  position: relative;
  overflow: hidden; }

.care2PetitionEmbed {
  margin: 0 auto; }

.credits {
  text-align: center;
  text-transform: none;
  padding: 12% 5%;
  font-size: 1.2rem; }
  .credits span {
    font-weight: bold; }
  .credits em {
    font-style: normal; }
  .credits .alignRight {
    text-align: right; }
  .credits .alignLeft {
    text-align: left; }

.react-multiple-carousel__arrow {
  background-color: #9B4237; }

.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px); }
  .react-multiple-carousel__arrow--left:before {
    left: -2px; }

.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px); }
  .react-multiple-carousel__arrow--right:before {
    left: 1px; }

iframe #player.player.vp-sidedock {
  top: 2.5rem !important; }

.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.xs-hidden {
  display: none !important; }

.sidebarMenuInner {
  margin: 0;
  padding: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .sidebarMenuInner nav {
    position: fixed;
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    background-color: #111; }
  .sidebarMenuInner ul {
    -webkit-padding-start: 0;
            padding-inline-start: 0; }
  .sidebarMenuInner li, .sidebarMenuInner ul label.title, .sidebarMenuInner ul, .sidebarMenuInner a {
    width: 100%;
    color: #FFF;
    font-family: 'Montserrat', 'Open Sans', sans-serif;
    display: block;
    font-weight: bold; }
  .sidebarMenuInner a {
    font-size: 1rem;
    text-decoration: none;
    color: #FFF;
    display: block;
    padding: 10px 25px;
    -webkit-transition: all .25s;
    transition: all .25s; }
    .sidebarMenuInner a:hover {
      background-color: #444;
      box-shadow: inset 5px 0px 0px 0px #fff; }
  .sidebarMenuInner button {
    font-size: 1.2rem;
    background: -webkit-gradient(linear, left top, left bottom, from(#101010), to(#202020));
    background: -webkit-linear-gradient(#101010, #202020);
    background: linear-gradient(#101010, #202020);
    padding: 14px 1.4rem;
    margin-bottom: 0;
    font-weight: bold;
    border-color: #202020;
    width: 100%;
    border: none;
    text-align: left;
    color: #fff;
    cursor: pointer;
    -webkit-transition: all .25s;
    transition: all .25s; }
    .sidebarMenuInner button:hover {
      text-shadow: 0px 0px 10px #fff;
      box-shadow: none; }
  .sidebarMenuInner #accordionGroup .otherLink {
    border-bottom: none; }
  .sidebarMenuInner li {
    display: block; }
  .sidebarMenuInner input[type="radio"] {
    display: none; }
  .sidebarMenuInner .otherLink {
    border-bottom: 1px solid #202020; }
  .sidebarMenuInner #chapters:checked + li {
    height: 19.5rem;
    border-bottom: 1px solid #202020; }
  .sidebarMenuInner #impact:checked + li {
    height: 17rem;
    border-bottom: 1px solid #202020; }
  .sidebarMenuInner i {
    margin-right: 12px; }

input[type="checkbox"]:checked ~ #sidebarMenu {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.closeMenu {
  -webkit-transform: translateX(-250px) !important;
          transform: translateX(-250px) !important; }

.openMenu {
  -webkit-transform: translateX(0) !important;
          transform: translateX(0) !important; }

input[type=checkbox] {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-sizing: border-box;
  display: none; }

#sidebarIconToggle {
  border: none;
  background-color: rgba(60, 60, 60, 0.4);
  padding: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  z-index: 99999;
  height: 100%;
  width: 100%;
  top: 2%;
  left: 15px;
  height: 32px;
  width: 35px; }

.spinner {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #fff; }

.horizontal {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px; }

.straight.part-1 {
  margin: 3px 0 1px;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-sizing: border-box;
  float: left; }

.straight.part-2 {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 4px;
  margin-bottom: 3px; }

.horizontally {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0; }

.diagonal.part-1 {
  margin: 3px 0 1px;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-sizing: border-box;
  float: left;
  transition: all 0.3s;
  box-sizing: border-box;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  margin-top: 8px; }

.diagonal.part-2 {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 4px;
  margin-bottom: 3px;
  transition: all 0.3s;
  box-sizing: border-box;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  margin-top: -9px; }

.App {
  text-align: center; }

.App-logo {
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #09d3ac; }

body {
  background-color: #000 !important;
  color: #fff;
  font-family: 'Special Elite', cursive; }

@-webkit-keyframes zoomin {
  0% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes zoomin {
  0% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.confineWidth {
  max-width: 1400px;
  margin: auto; }

.home.confineWidth {
  max-width: none; }

.animate-up {
  height: 100%;
  width: 100% !important;
  position: absolute;
  max-width: none;
  -webkit-animation: zoomin 10s;
  background-size: cover;
  background: url(/opening.jpg) no-repeat center center fixed;
  background-size: cover;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%; }

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.player-wrapper {
  z-index: 1;
  position: relative;
  padding-top: 56.25%; }

.react-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0; }
  .react-player video {
    height: 96vh !important;
    width: auto !important; }

.increase-z-index {
  z-index: 999; }

.home .react-player {
  height: 100vh !important;
  overflow: hidden; }

.image, .hoverOverlay {
  display: block;
  width: 100%;
  z-index: 2;
  height: auto; }

.item {
  background-color: transparent;
  border: none;
  margin: 3px;
  position: relative; }
  .item:hover .hoverOverlay {
    opacity: 1; }

.react-multi-carousel-track {
  margin-bottom: 1rem; }

.selected .hoverOverlay {
  opacity: 1; }

.hoverOverlay {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0; }

.carousel .image {
  margin-top: 0.5em; }

.feature {
  height: 100%;
  width: 100%;
  -webkit-transition: .5s ease;
  transition: .5s ease; }

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  left: 0;
  right: 0;
  height: 102%;
  width: 100%;
  opacity: 1;
  -webkit-transition: .5s ease;
  transition: .5s ease;
  background: -webkit-gradient(linear, left top, right top, from(black), color-stop(11%, #01000c), color-stop(51%, rgba(1, 0, 22, 0)), to(black));
  background: -webkit-linear-gradient(left, black 0%, #01000c 11%, rgba(1, 0, 22, 0) 51%, black 100%);
  background: linear-gradient(90deg, black 0%, #01000c 11%, rgba(1, 0, 22, 0) 51%, black 100%); }

.listLocations {
  width: 334PX;
  background-color: #FFFFFF;
  overflow: hidden;
  height: calc(100vh - 95px);
  margin-top: 60px; }
  .listLocations .table {
    margin-bottom: 0 !important; }
  .listLocations .react-bs-container-body {
    height: 100%;
    max-height: calc(100vh - 187px);
    /* padding-bottom: 58px; */
    overflow-y: scroll; }

.topInfo {
  font-size: 1.4rem;
  line-height: 1.2em;
  margin: 0 7.3em 0 0;
  display: block;
  position: absolute;
  bottom: 4.5em;
  left: 350px;
  color: white;
  z-index: 99999;
  background-color: rgba(131, 73, 61, 0.7);
  padding: 2.5em 1.5em 1.5em; }
  .topInfo svg {
    position: absolute;
    right: 0.5em;
    top: 0.5em; }

#googleMap {
  position: absolute;
  top: 0;
  bottom: 4em;
  left: 334PX;
  right: 0;
  background-color: black;
  position: absolute;
  z-index: 1;
  padding: 60px 0 0; }
  #googleMap .bottomInfo {
    font-size: 1rem;
    line-height: 1.2em;
    /* margin: 0 6em 1em 3em; */
    /* height: 10em; */
    display: block;
    background-color: grey;
    padding: 5px; }

.infoBox {
  color: #000;
  box-shadow: 1px 1px 26px -8px rgba(0, 0, 0, 0.27); }
  .infoBox .infoTitle {
    padding: 1em 1em 0;
    display: block; }
  .infoBox .infoDesc {
    padding: 0 1em 1em;
    line-height: 1.3em;
    display: block; }
    .infoBox .infoDesc span span {
      padding-right: 3px; }
    .infoBox .infoDesc span span br {
      display: none; }
    .infoBox .infoDesc span span div br {
      display: block; }

#zoom-extends-btn {
  position: absolute;
  bottom: 2.1em;
  cursor: pointer;
  background-color: #508A5F;
  right: 3.6rem;
  padding: 0.5em 0.65em;
  color: white !important;
  font-weight: bold;
  font-size: 0.8rem; }

#close-map {
  position: absolute;
  top: 0.3em;
  cursor: pointer;
  /* background-color: #0071bc; */
  right: 0.5em;
  /* padding: 0.5em 0.65em; */
  color: white !important;
  font-weight: bold;
  font-size: 2em; }

.imgHome {
  width: 100%; }

.imgNone {
  width: 100%;
  padding: 1em 1em 1em; }

.popupOverlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  position: absolute; }

.textArea {
  color: white;
  font-size: 0.9em;
  line-height: 0.9em;
  z-index: 3;
  position: absolute;
  top: 19%;
  max-width: 53rem;
  left: 0;
  padding: 2em; }
  .textArea h2 {
    font-size: 1.5rem;
    line-height: 1.8rem;
    padding-bottom: 0.1rem; }
  .textArea span {
    max-width: 34rem;
    display: block;
    line-height: 1.3em;
    margin-bottom: 2rem; }

.homeLogo {
  animation: fadein 8s;
  -moz-animation: fadein 8s;
  /* Firefox */
  -webkit-animation: fadein 8s;
  /* Safari and Chrome */
  -o-animation: fadein 8s;
  /* Opera */
  -webkit-animation-delay: 8s;
          animation-delay: 8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;
  line-height: 120%;
  font-weight: 800;
  font-size: 2.3rem; }
  .homeLogo span {
    color: #BF9671;
    font-size: 2.7rem;
    text-transform: uppercase;
    -webkit-animation-name: textFlicker;
            animation-name: textFlicker;
    -webkit-animation-duration: 20s;
            animation-duration: 20s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    /* New code here: */
    -webkit-animation-delay: 6s;
            animation-delay: 6s; }
  .homeLogo em {
    color: #fff;
    padding-top: 1em;
    font-style: normal;
    display: block;
    text-transform: uppercase;
    font-size: .9rem;
    display: block;
    line-height: 1.2em; }

.homeOverlay {
  z-index: 99;
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 13em; }

@-webkit-keyframes textFlicker {
  0% {
    opacity: 0.8;
    color: #A88A90; }
  2% {
    opacity: 0.9; }
  8% {
    opacity: 0.7; }
  12% {
    opacity: 0.8;
    color: #D5C4D0; }
  16% {
    opacity: 1; }
  19% {
    opacity: 0.8; }
  22% {
    opacity: 0.9; }
  25% {
    opacity: 0.8;
    color: #D0B5B9; }
  30% {
    opacity: 1;
    color: #ECD8E9; }
  35% {
    opacity: 0.9; }
  40% {
    opacity: 0.7; }
  42% {
    opacity: 0.5;
    color: #B6A7D3; }
  46% {
    opacity: 0.9; }
  52% {
    opacity: 1; }
  57% {
    opacity: 0.7; }
  62% {
    opacity: 0.6;
    color: #AF9EC8; }
  68% {
    opacity: 0.7; }
  70% {
    opacity: 0.5;
    color: #B2928B; }
  77% {
    opacity: .9;
    color: #DCD0DE; }
  80% {
    opacity: 0.5; }
  90% {
    opacity: .9;
    color: #F9E5D4; }
  92% {
    opacity: 0.5; }
  98% {
    opacity: 0.7; }
  100% {
    opacity: .9;
    color: #E0C2A4; } }

@keyframes textFlicker {
  0% {
    opacity: 0.8;
    color: #A88A90; }
  2% {
    opacity: 0.9; }
  8% {
    opacity: 0.7; }
  12% {
    opacity: 0.8;
    color: #D5C4D0; }
  16% {
    opacity: 1; }
  19% {
    opacity: 0.8; }
  22% {
    opacity: 0.9; }
  25% {
    opacity: 0.8;
    color: #D0B5B9; }
  30% {
    opacity: 1;
    color: #ECD8E9; }
  35% {
    opacity: 0.9; }
  40% {
    opacity: 0.7; }
  42% {
    opacity: 0.5;
    color: #B6A7D3; }
  46% {
    opacity: 0.9; }
  52% {
    opacity: 1; }
  57% {
    opacity: 0.7; }
  62% {
    opacity: 0.6;
    color: #AF9EC8; }
  68% {
    opacity: 0.7; }
  70% {
    opacity: 0.5;
    color: #B2928B; }
  77% {
    opacity: .9;
    color: #DCD0DE; }
  80% {
    opacity: 0.5; }
  90% {
    opacity: .9;
    color: #F9E5D4; }
  92% {
    opacity: 0.5; }
  98% {
    opacity: 0.7; }
  100% {
    opacity: .9;
    color: #E0C2A4; } }

.start {
  animation: fadein 5s;
  -moz-animation: fadein 5s;
  -webkit-animation: fadein 5s;
  -o-animation: fadein 5s;
  -webkit-animation-delay: 8s;
          animation-delay: 8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;
  display: block;
  font-weight: bold;
  font-size: 1.6rem;
  margin: 6% auto 0;
  color: #fff;
  max-width: 10em;
  text-transform: uppercase; }
  .start:hover {
    text-decoration: none;
    color: #666; }

.videoModal {
  opacity: 0;
  border-bottom: 1px solid white;
  -moz-animation: fadein 5s;
  -webkit-animation: fadein 5s;
  -o-animation: fadein 5s;
  -webkit-animation-delay: 8s;
          animation-delay: 8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  max-width: 136px;
  margin: 0 auto;
  padding: 0.75em 0 2px; }
  .videoModal:hover {
    color: #666;
    border-color: #666; }

.share {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 6px;
  bottom: 10px;
  z-index: 3;
  width: 96px; }
  .share .share-slideout {
    margin-right: 38px;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 4px 10px;
    opacity: 0;
    color: black;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    border-radius: 10px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .share svg {
    color: white; }
  .share button {
    position: absolute;
    top: 0;
    border: none;
    right: 0;
    width: 40px !important;
    height: 32px;
    padding: 1px 10px;
    background-color: rgba(60, 60, 60, 0.4); }
  .share:hover .share-slideout, .share:active .share-slideout {
    opacity: 1;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: scaleX(1);
            transform: scaleX(1); }

.closeshare {
  position: absolute;
  right: 0; }

.share .modal-dialog {
  padding: 8% 0 5%; }
  .share .modal-dialog .close {
    color: #fff !important; }
  .share .modal-dialog .modal-header {
    border: none; }
  .share .modal-dialog .SocialMediaShareButton--facebook {
    background-color: #3C5A99;
    padding: 10px 1rem;
    font-weight: bold; }
  .share .modal-dialog .SocialMediaShareButton--twitter {
    background-color: #1DA1F2;
    padding: 10px 1rem;
    font-weight: bold; }

.modal-content {
  background: #202020;
  padding: 5%; }

.videoclose {
  position: absolute;
  top: 0.2em;
  z-index: 999;
  right: 0.6rem; }
  .videoclose span {
    color: white;
    text-shadow: 0px 0px 14px black; }

.poster {
  position: absolute;
  top: 0;
  width: 100%; }

.videoclose.close {
  font-size: 3em; }

.gallerySection {
  max-width: 1580px;
  margin: 0 1.5em; }

@-webkit-keyframes fadeUp {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes fadeUp {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    background-color: #fff;
    color: black;
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #fff;
    color: black;
    opacity: 0.5; } }

@-webkit-keyframes pulsing {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }
  70% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    background-color: #fff; } }

@keyframes pulsing {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    background-color: #fff;
    color: black;
    opacity: 0.5; }
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #fff;
    color: black;
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    background-color: #fff;
    color: black;
    opacity: 0.5; } }

.nextPart {
  background-color: #fff;
  padding: 0.3em 0 0.3em;
  display: block;
  color: #fff;
  position: relative;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  height: 100%;
  -webkit-animation: pulsing 2s infinite;
          animation: pulsing 2s infinite; }
  .nextPart .arrowBlock {
    position: relative;
    float: right;
    width: 10.6rem; }
  .nextPart span {
    font-size: 0.9rem;
    display: block;
    line-height: 15px; }
  .nextPart em {
    display: block;
    text-transform: uppercase;
    font-style: normal;
    line-height: 1.3em;
    font-weight: bold; }
  .nextPart svg {
    font-size: 3em;
    position: absolute;
    top: 0;
    right: 0.4em; }
  .nextPart a {
    color: #000; }
    .nextPart a:hover {
      text-decoration: none; }
  .nextPart:hover {
    -webkit-animation: fadeUp 0.5s;
            animation: fadeUp 0.5s;
    -webkit-transform: scale(1.025);
            transform: scale(1.025);
    color: #000;
    text-decoration: none;
    opacity: 1;
    background-color: #fff; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.react-h5-audio-player {
  float: right;
  margin-right: 13px; }
  .react-h5-audio-player .flex {
    display: inline-block !important;
    background-color: transparent !important;
    box-sizing: border-box;
    width: 24px !important;
    height: 24px !important;
    padding: 4px 0 0 !important; }
    .react-h5-audio-player .flex .time, .react-h5-audio-player .flex .progress-bar-wrapper {
      display: none !important; }
    .react-h5-audio-player .flex .toggle-play-button {
      background-color: transparent !important;
      color: #666 !important;
      box-sizing: border-box;
      width: 0 !important;
      height: 0 !important;
      position: relative !important;
      left: 0 !important;
      margin-left: 0 !important; }
    .react-h5-audio-player .flex .play-icon {
      border-left: 16px solid #666 !important;
      border-top: 8px solid transparent !important;
      border-bottom: 8px solid transparent !important; }
      .react-h5-audio-player .flex .play-icon:hover {
        border-left-color: #fff !important; }
    .react-h5-audio-player .flex .pause-icon {
      border-left: 6px solid #666 !important;
      width: 8px !important;
      height: 15px !important;
      box-shadow: black 2px 0px 0px 0px inset !important;
      background-color: #666 !important;
      border-radius: 2px; }
      .react-h5-audio-player .flex .pause-icon:hover {
        border-color: #fff !important;
        background-color: #fff !important; }

.prevPart {
  background-color: rgba(60, 60, 60, 0.4);
  padding: 0.3em 1em 0.3em 4em;
  display: block;
  border: none;
  color: #fff;
  position: relative;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out; }
  .prevPart a {
    color: #fff; }
    .prevPart a:hover {
      color: #000;
      text-decoration: none; }
  .prevPart:hover {
    -webkit-transform: scale(1.025);
            transform: scale(1.025);
    color: #000;
    text-decoration: none;
    background-color: #fff; }
    .prevPart:hover a {
      color: #000;
      text-decoration: none; }
  .prevPart span {
    font-size: 0.9rem;
    display: block;
    line-height: 15px; }
  .prevPart em {
    display: block;
    text-transform: uppercase;
    font-style: normal;
    line-height: 1.3em;
    font-weight: bold; }
  .prevPart svg {
    font-size: 3em;
    position: absolute;
    top: 0.1em;
    left: 0.4em; }

.playButton {
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  margin-top: 0.5em;
  /* background-color: #e5e5e5; */
  background-color: rgba(60, 60, 60, 0.4);
  border-width: 0;
  padding: 0.5em 2em;
  /* height: 1.2vw; */
  color: white; }
  .playButton em {
    font-style: normal; }
  .playButton svg {
    margin-right: 7px; }
  .playButton:hover {
    background-color: #fff;
    color: #000;
    -webkit-transform: scale(1.025);
            transform: scale(1.025); }

.xs-visible {
  display: none !important; }

.footer {
  background-color: #000;
  position: fixed;
  bottom: 0;
  z-index: 999;
  padding: 5px 1.5rem; }
  .footer .footerButton {
    padding: 0 10px;
    display: inline-block;
    color: #666; }
    .footer .footerButton:hover {
      text-decoration: none;
      color: #fff; }
  .footer .greySlash {
    display: inline-block;
    color: #666; }
  .footer .shareFooter {
    position: absolute;
    right: 0; }
  .footer .muteButton button {
    background-color: transparent;
    border: none;
    color: #666;
    min-width: 25px; }

.footerSupporters {
  opacity: 1;
  position: relative;
  bottom: 1.5rem; }

.brandingImg {
  width: 75%;
  max-width: 200px;
  margin: 0 auto;
  display: block; }

@media (max-width: 600px) {
  .xs-hidden {
    display: none !important; }
  .xs-visible {
    display: block !important; } }

.galleryFixed {
  position: absolute;
  bottom: 1rem;
  width: 95%; }

@media (min-width: 576px) and (max-width: 900px) {
  .changeBlock.container {
    max-width: 675px; } }

@media (min-width: 600px) {
  .xs-hidden {
    display: block !important; }
  .sidebarIconToggle {
    top: 22px; }
  .textArea {
    font-size: 1rem;
    top: 19%;
    padding: 1.5rem 2rem; }
    .textArea h2 {
      font-size: 1.8rem;
      line-height: 2rem;
      padding-bottom: 1rem; }
    .textArea span {
      line-height: 1.4rem;
      margin-bottom: 1rem; }
  .nextPart {
    border-left: none;
    right: 1.5em;
    padding: 0.3em 4.2em 0.4em 2.5em;
    position: absolute;
    top: 0.8em;
    height: auto;
    height: initial;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out; }
    .nextPart svg {
      top: 0.1em; }
  .prevPart {
    padding: 0.3em 2.5em 0.4em 4.2em;
    display: block;
    position: absolute;
    left: 4.5em;
    top: 0.8em; } }

@media (min-width: 700px) {
  .xs-hidden {
    display: block !important; }
  .sidebarIconToggle {
    top: 22px; }
  .textArea {
    font-size: 1.2rem;
    top: 19%; }
    .textArea h2 {
      font-size: 2.3rem;
      line-height: 2.3rem;
      padding-bottom: 1rem; }
    .textArea span {
      margin-bottom: 1rem;
      line-height: 1.3em; }
  .prevPart {
    display: block;
    position: absolute;
    left: 4.5em;
    top: 0.8em; } }

@media (min-width: 767px) {
  .homeOverlay {
    bottom: 18%; }
    .homeOverlay span {
      font-size: 4rem; }
    .homeOverlay em {
      font-size: 1rem; }
  .footerSupporters {
    opacity: 0;
    animation: fadein 5s;
    -moz-animation: fadein 5s;
    -webkit-animation: fadein 5s;
    -o-animation: fadein 5s;
    -webkit-animation-delay: 8s;
            animation-delay: 8s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .start {
    margin-top: 2.5rem; }
  .footerSupporters {
    bottom: 0;
    max-width: 1200px;
    margin: 0 auto; } }

@media (min-width: 800px) {
  .textArea {
    padding: 2rem 5%; } }

@media (min-width: 850px) {
  .xs-hidden {
    display: block !important; }
  .sidebarIconToggle {
    top: 22px; }
  .textArea {
    text-shadow: 10px 10px 23px black;
    font-size: 20px;
    left: 0;
    top: 19%; }
    .textArea h2 {
      font-size: 2.5rem;
      line-height: 2.5rem;
      padding-bottom: 2rem; } }

@media (min-width: 994px) and (max-width: 1250px) {
  .changeBlock .col-lg-5 {
    flex: 0 0 90%;
    max-width: 90%;
    margin: 0 auto; }
  .changeBlock .col-lg-7 {
    flex: 0 0 90%;
    max-width: 650px;
    margin: 0 auto; } }

@media (min-width: 1000px) {
  .changeBlock {
    padding: 9% 2%; } }

@media (min-width: 1130px) {
  .gallerySection {
    position: absolute;
    bottom: 2rem;
    width: 95%; }
  .copyRight {
    margin-top: 8%; }
  .homeOverlay {
    bottom: 18%; }
    .homeOverlay span {
      font-size: 4.5rem; }
    .homeOverlay em {
      font-size: 1.1rem; }
  .start {
    margin-top: 2rem; } }

@media (min-width: 1100px) {
  .react-player video {
    object-fit: cover;
    height: 100% !important;
    width: 100% !important; } }

@media (min-width: 1245px) {
  .confineWidth {
    width: 90vw; }
  .confineWidth.home {
    width: auto; } }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

