@import url('https://fonts.googleapis.com/css?family=Open+Sans|Special+Elite&display=swap');

@import url('https://fonts.googleapis.com/css?family=Varela+Round');

@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

.listLocations .react-bs-table-container {
  /* Force table to not be like tables anymore */
  table, thead, tbody, td, tr {
    display: block;
  }

  td {
    padding: 0;
    // min-height: 28px;
    p {
      margin-bottom: 0;
    }
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  // thead tr {
  //
  // }
  thead tr {
    position: relative;
    top: 0;
    left: 0;
  }

  thead tr th.actions {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }


  tr { border: 1px solid #ccc; }

  td {
    /* Behave  like a "row" */
    border: none;
    // border-bottom: 1px solid #eee;
    position: relative;
    // padding-left: 1em !important;
    padding-left: 0;
    &:focus {
      outline: none;
      p {
        border-bottom: 1px dotted grey;
      }
    }
  }

.copy-place {
  padding: 0 1em 1em;
}
.copy-title {
  padding: 1em 1em 0;
}
  // td:before {
  //   /* Now like a table header */
  //   position: absolute;
  //   /* Top/left values mimic padding */
  //   top: 12px;
  //   left: 6px;
  //   width: 30%;
  //   padding-right: 10px;
  //   white-space: nowrap;
  // }
}


.allLocations .listLocations .react-bs-table-container {
  td {
    padding-left: 0;
    padding-bottom: 0;
  }
}


.swiper-button-prev, .swiper-button-next {
      background-color: #9b4237;
      background-image: none;
      color: #fff;
      font-size: 2em;
    line-height: 1em;
        border-radius: 50%;
        width: 44px;
        border: 0;
        padding-left: 1px;

}

.swiper-button-next {
  padding-left: 0.7rem;
}

.swiper-pagination-bullet-active {
    background: #9b4237;
}



@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}

.impactIntro {
  font-size: 1.5rem;
  line-height: 1.9rem;
  padding: 0.5rem;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
  -webkit-animation-delay: 1s;
  -webkit-animation-name: fadein;
  -webkit-animation-duration: 5s;
  -webkit-animation-timing-function: linear;
  animation-delay: 1s;
  animation-name: fadein;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.impactClick {
  font-size: 1.5rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  position: absolute;
  right: 1em;
  width: 9em;
  top: 4.5rem;
  margin: 0 auto;
  text-align: center;
  opacity: 0;
  -webkit-animation-delay: 7s;
  -webkit-animation-name: fadein;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  animation-delay: 7s;
  animation-name: fadein;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  svg {
    font-size: 4rem;
    display: block;
text-align: center;
margin: 0 auto -1.8rem;
  }
}

button:focus, a:focus {
  outline: 1px dotted white
}

.sidebar {
  padding-top: 60px;
  z-index: 999;
  position: absolute;
  min-width: 250px;
  max-width: 250px;
  color: #fff;
  margin-left: -250px;
  transition: all 0.5s;
  background-color: #181818;
  height: calc(100vh - 30px);
  overflow: hidden;
}

.sidebarMenuInner {
  overflow-y: scroll;
  height: 100%;
}

.sidebar.is-open {
  margin-left: 0;
  transition: .5s;
}


#accordionGroup {
  .Accordion-panel {
    color: #fff;
    padding: 1em 0;
    border-bottom: 1px solid #202020;
  }
}


.textCredits {
  animation: fadein 5s;
  -moz-animation: fadein 5s;
  -webkit-animation: fadein 5s;
  -o-animation: fadein 5s;
  animation-delay: 5s;
    animation-fill-mode: forwards;
}

.mobileBackground {
  background: url("/opening.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
background-size: cover;
position: absolute;
// -webkit-animation: zoomin 10s;
top: 0;
width: 100%;
height: 100%;
}

.maxWidth {
  height: 100vh;
    &:after {
    content: "";
    background:  url("/home-background.png");
    opacity: 0.6x;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
  .mobileOverlay{
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    height: 100%;
    Flex-direction: column;

    text-align: center;

  }
  .homeOverlay {
    position: absolute;
    bottom: 6%;
    left: 0;
    width: 100%;
    // height: 95px;
    z-index: 1;
    animation: fadein 1s;
    -moz-animation: fadein 1s;
    -webkit-animation: fadein 1s;
    -o-animation: fadein 1s;
    animation-delay: 0s;
      animation-fill-mode: forwards;
  }
  .homeLogo {
    animation: fadein 0.1s;
    -moz-animation: fadein 0.1s;
    -webkit-animation: fadein 0.1s;
    -o-animation: fadein 0.1s;
    animation-delay: 0.1s;
      animation-fill-mode: forwards;
    opacity: 0;
    line-height: 120%;
    font-weight: 800;
    font-size: 2.3rem;
  }
  .blurb {
    font-size: 1.7rem;
    text-transform: initial;
        z-index: 1;
        line-height: 1.7rem
  }

}

.blurbTablet {
  font-size: 1.5rem;
      font-weight: normal;
      margin-top: 1em;
      position: absolute;
      bottom: 5em;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0;
      position: absolute;
      width: 93%;
      margin: 0 3%;


}


.logo {
  font-weight: 800;
  font-size: 3rem;
  color: #BF9671;
  text-transform: uppercase;
}

.aboutBlock {
  text-align: left;
  padding: 19% 5%;
  font-size: 1.5rem;
  h2 {
    text-align: center;
  }
}

.shameClick {
  position: relative;
  overflow: hidden;
  img {
      width: 98%;
      margin: 1%;
  }
  img.shame-hover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
  }
  &:hover img { /* it's optional - for nicer transition effect */
    opacity: 0;
  }
  &:hover img.shame-hover {
    opacity: 1;
  }
}

.teacherImg {
  position: relative;
  img {
      width: 100%;
      // margin: 1%;
  }
  img.banner-hover {
    position: absolute;
    top: 0;
    // right: 0;
    // left: 0;
    // bottom: 0;
    opacity: 0;
  }
  &:hover img { /* it's optional - for nicer transition effect */
    opacity: 0;
  }
  &:hover img.banner-hover {
    opacity: 1;
  }
}
.InvolvedBlock, .changeBlock {
  padding: 10% 7% ;
  font-size: 1.5rem;
  .highlightCountry {
    margin: 1em;
    border-bottom: 1px solid white;
}
  h2 {
    font-size: 2.8rem;
    color: #BF9671;
    font-weight: bold;
  }
  h3 {
    line-height: 2.3rem;
  }
  a {
    color: white;
    display: flex;
    &:hover {
      color: #BF9671;
      text-decoration: none;
    }
    svg {
      margin-right: 0.5em;
      }

  }
  .country {
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
  }
  .sectionInvolved {
    padding: 0 1em;
  }
}
.changeBlock {
  text-align: center;
  padding: 10% 3% ;
  .col-lg-5 {
    text-align: left;
        padding: 0 1.2rem;
  }
}

.resp-container {
    position: relative;
    overflow: hidden;
}

.care2PetitionEmbed {
  margin: 0 auto;
}


.credits {
  text-align: center;
  text-transform: none;
  padding: 12% 5%;
  font-size: 1.2rem;

  span {
    font-weight: bold;
  }
  em {
    font-style: normal;
  }

  .alignRight {
    text-align: right;
  }
  .alignLeft {
    text-align: left;
  }
}

.react-multiple-carousel__arrow {
  background-color: #9B4237
}

.react-multiple-carousel__arrow--left {
    left: calc(1% + 1px);
    &:before {
      left: -2px;
    }
}
.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px);
  &:before {
    left: 1px;
  }
}

iframe #player.player.vp-sidedock {
   top: 2.5rem !important;
}

.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.xs-hidden {
  display: none !important;
}

.sidebarMenuInner {
    margin:0;
    padding:0;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    nav {
    	position: fixed;
    	display: flex;
    	align-items: flex-start;
    	width: 100%;
    	height: 100%;
    	background-color: #111;
    }
    ul {
      padding-inline-start: 0;
    }
    li, ul label.title, ul, a {
    	width: 100%;
    	color: #FFF;
    	font-family: 'Montserrat', 'Open Sans', sans-serif;
    	display: block;
    	font-weight: bold;
    }
    a {
    	font-size: 1rem;
    	text-decoration: none;
    	color: #FFF;
    	display: block;
    	padding: 10px 25px;
    	transition: all .25s;
      &:hover {
      	background-color: #444;
        box-shadow: inset 5px 0px 0px 0px #fff;
      }
    }
    button {
    	font-size: 1.2rem;
    	background: linear-gradient(#101010, #202020);
    	padding: 14px 1.4rem;
      margin-bottom: 0;
      font-weight: bold;
      border-color: #202020;
      width: 100%;
      border: none;
      text-align: left;
      color: #fff;
    	cursor: pointer;
      transition: all .25s;
      &:hover {
        text-shadow: 0px 0px 10px #fff;
        box-shadow: none;
      }

    }
    #accordionGroup {
      .otherLink {
        border-bottom: none;
      }

    }

    li {
    	display: block;
    }


    input[type="radio"] {
    	display: none;
    }
    .otherLink {
      border-bottom: 1px solid #202020;
    }
    #chapters:checked + li  {
      height: 19.5rem;
      border-bottom: 1px solid #202020;
    }
    #impact:checked + li {
    	height: 17rem;
      border-bottom: 1px solid #202020;
    }
    i {
    	margin-right: 12px;
    }
}
input[type="checkbox"]:checked ~ #sidebarMenu {
    transform: translateX(0);
}

.closeMenu {
  transform: translateX(-250px) !important;
}

.openMenu {
  transform: translateX(0) !important;
}

input[type=checkbox] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
}


#sidebarIconToggle {
    border: none;
    background-color: rgba(60, 60, 60, 0.4);
    padding: 5px;
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 99999;
    height: 100%;
    width: 100%;
    top: 2%;
    left: 15px;
    height: 32px;
    width: 35px;
}
.spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #fff;
}
.horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

.straight.part-1 {
    margin: 3px 0 1px;
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
}
.straight.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 4px;
    margin-bottom: 3px;
}

.horizontally {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
}

.diagonal.part-1 {
    margin: 3px 0 1px;
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
}

.diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 4px;
    margin-bottom: 3px;
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -9px;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


body {
  background-color: #000 !important;
  color: #fff;
  font-family: 'Special Elite', cursive;
}

@-webkit-keyframes zoomin {
  0% {transform: scale(1.2);}
  // 25% {transform: scale(1.25);}
  100% {transform: scale(1);}
}
@keyframes zoomin {
  0% {transform: scale(1.2);}
  // 25% {transform: scale(1.25);}
  100% {transform: scale(1);}
}

.confineWidth {
  max-width: 1400px;
  margin: auto;
}

.home.confineWidth {

max-width: none;

}



.animate-up {

height: 100%;

width: 100% !important;

position: absolute;

  max-width: none;
   -webkit-animation: zoomin 10s;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background: url(/opening.jpg) no-repeat center center fixed;
  background-size: cover;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.player-wrapper {
  z-index: 1;
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;

  video {
    height: 96vh !important;
    width: auto !important;
  }
}
.increase-z-index {
  z-index: 999;
}

.home {
  .react-player {
  height: 100vh !important;
    overflow: hidden;
  }
}

.image, .hoverOverlay {
  display: block;
  width: 100%;
  z-index: 2;
  height: auto;
}

.item {
  background-color: transparent;
  border: none;
  margin: 3px;
  position: relative;
  &:hover {
    .hoverOverlay {
      opacity: 1;
    }

  }
}
.react-multi-carousel-track  {
  margin-bottom: 1rem;
}

.selected .hoverOverlay {
  opacity: 1;
}

.hoverOverlay {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
}

.carousel .image {
  margin-top: 0.5em;
}

.feature {
  height: 100%;
  width: 100%;
  transition: .5s ease;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  left: 0;
  right: 0;
  height: 102%;
  width: 100%;
  opacity: 1;
  transition: .5s ease;
  background: linear-gradient(90deg, black 0%, #01000c 11%, rgba(1, 0, 22, 0) 51%, black 100%);
}

.listLocations {
  width: 334PX;
  background-color: #FFFFFF;
  overflow: hidden;
  height: calc(100vh - 95px);;
  margin-top: 60px;
  .table {
    margin-bottom: 0 !important;
  }
  .react-bs-container-body {
    height: 100%;
    max-height: calc(100vh - 187px);
        /* padding-bottom: 58px; */
        overflow-y: scroll;
  }
}

.topInfo {
  font-size: 1.4rem;
  line-height: 1.2em;
  margin: 0 7.3em 0 0;
  display: block;
  position: absolute;
  bottom: 4.5em;
  left: 350px;
  color: white;
  z-index: 99999;
  background-color: rgba(131, 73, 61, 0.7);
  padding: 2.5em 1.5em 1.5em;
  svg {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
  }
}

#googleMap {
  position: absolute;
  top: 0;
  bottom: 4em;
  left: 334PX;
  right: 0;
  background-color: black;
  position: absolute;
  z-index: 1;
  padding: 60px 0 0;

  .bottomInfo {
    font-size: 1rem;
    line-height: 1.2em;
    /* margin: 0 6em 1em 3em; */
    /* height: 10em; */
    display: block;
    background-color: grey;
    padding: 5px;
  }
}

.infoBox {
  color: #000;
  -webkit-box-shadow: 1px 1px 26px -8px rgba(0,0,0,0.27);
  -moz-box-shadow: 1px 1px 26px -8px rgba(0,0,0,0.27);
  box-shadow: 1px 1px 26px -8px rgba(0,0,0,0.27);
  .infoTitle {
    padding: 1em 1em 0;
    display: block;
  }
  .infoDesc {
    padding: 0 1em 1em;
    line-height: 1.3em;
    display: block;
    span span {
      padding-right: 3px;
    }
    span span br {
      display: none;
    }
    span span div br {
      display: block;
    }
  }


}

#zoom-extends-btn {
  position: absolute;
  bottom: 2.1em;
  cursor: pointer;
  background-color: #508A5F;
  right: 3.6rem;
  padding: 0.5em 0.65em;
  color: white !important;
  font-weight: bold;
  font-size: 0.8rem;
}

#close-map {
  position: absolute;
  top: 0.3em;
  cursor: pointer;
  /* background-color: #0071bc; */
  right: 0.5em;
  /* padding: 0.5em 0.65em; */
  color: white !important;
  font-weight: bold;
  font-size: 2em;
}

.imgHome {
  width: 100%;
}

.imgNone {
  width: 100%;
  padding: 1em 1em 1em;
}

.popupOverlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  position: absolute;
}

.textArea {
  color: white;
  font-size: 0.9em;
  line-height: 0.9em;
  z-index: 3;
  position: absolute;
  top: 19%;
  max-width: 53rem;
  left: 0;
  padding: 2em;
  h2 {
    font-size: 1.5rem;
    line-height: 1.8rem;
    padding-bottom: 0.1rem;
  }
  span {
    max-width: 34rem;
    display: block;
    line-height: 1.3em;
    margin-bottom: 2rem;
   }
}

// $mod_1: 1.2; // mobile
// $mod_2: 1.3; // mobile
// $mod_3: 1.4; // desktop
// $mod_3: 1.5; // desktop

.homeLogo {
  animation: fadein 8s;
    -moz-animation: fadein 8s; /* Firefox */
    -webkit-animation: fadein 8s; /* Safari and Chrome */
    -o-animation: fadein 8s; /* Opera */
     animation-delay: 8s;
     animation-fill-mode: forwards;
      opacity: 0;
  line-height: 120%;
  span {
    color: #BF9671;
    font-size: 2.7rem;
    text-transform: uppercase;



    animation-name: textFlicker;
   animation-duration: 20s;
   animation-timing-function: linear;
   animation-iteration-count: infinite;
   /* New code here: */
   animation-delay: 6s;

  }
  em {
    color: #fff;
    padding-top: 1em;
    font-style: normal;
    display: block;
    text-transform: uppercase;
    font-size: .9rem;
      display: block;
      line-height: 1.2em;

  }

  font-weight: 800;
  font-size: 2.3rem;
}

.homeOverlay {
  z-index: 99;
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 13em;
}


  @keyframes textFlicker {
  0% {
    opacity:0.8;
    color: #A88A90;
  }

  2% {
    opacity:0.9;
  }
  8% {
    opacity:0.7;
  }
  12% {
    opacity:0.8;
    color: #D5C4D0;
  }
  16% {
    opacity:1;

  }
  19% {
    opacity:0.8;
  }
  22% {
    opacity:0.9;
  }

  25% {
    opacity:0.8;
    color: #D0B5B9;

  }
  30% {
    opacity:1;
    color: #ECD8E9;

  }
  35% {
    opacity:0.9;
  }
  40% {
    opacity:0.7;
  }
  42% {
    opacity:0.5;
    color: #B6A7D3;
  }
  46% {
    opacity:0.9;
  }
  52% {
    opacity:1;
  }
  57% {
    opacity:0.7;

  }

  62% {
    opacity:0.6;
    color: #AF9EC8;
  }
  68% {
    opacity:0.7;
  }
  70% {
    opacity:0.5;
    color: #B2928B;
  }

  77% {
    opacity:.9;
    color: #DCD0DE;
  }

  80% {
    opacity:0.5;
  }

  90% {
    opacity:.9;
    color: #F9E5D4
  }
  92% {
    opacity:0.5;
  }
  98% {
    opacity:0.7;
  }
  100% {
    opacity:.9;
    color: #E0C2A4
  }
}

.start {
  animation: fadein 5s;
      -moz-animation: fadein 5s;
      -webkit-animation: fadein 5s;
      -o-animation: fadein 5s;
      animation-delay: 8s;
      animation-fill-mode: forwards;
      opacity: 0;
      display: block;
      font-weight: bold;
      font-size: 1.6rem;
      margin: 6% auto 0;
      color: #fff;
      max-width: 10em;
      text-transform: uppercase;
  &:hover {
    text-decoration: none;
    color: #666;
  }
}
.videoModal {
  opacity: 0; border-bottom: 1px solid white;
  -moz-animation: fadein 5s;
  -webkit-animation: fadein 5s;
  -o-animation: fadein 5s;
  animation-delay: 8s;
  animation-fill-mode: forwards;
  max-width: 136px;
  margin: 0 auto;
  padding: 0.75em 0 2px;
  &:hover {
    // font-weight: bold;
    color: #666;
    border-color: #666;
  }
}
.share {
  background-color: transparent;
  border: none;
  // padding: 1px 10px;
  position: absolute;
  right: 6px;
  bottom: 10px;

  z-index: 3;
  width: 96px;
  .share-slideout {
    margin-right: 38px;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 4px 10px;
    opacity: 0;
    color: black;
    transform: scaleX(0);
    border-radius: 10px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  svg {color: white;}
  button {
    position: absolute;
    top: 0;
    border: none;
    right: 0;
    width: 40px !important;
    height: 32px;
    padding: 1px 10px;
    background-color: rgba(60, 60, 60, 0.4);

  }
  &:hover, &:active {
    .share-slideout {
      opacity: 1;
        transition:0.3s;
        transform: scaleX(1);
    }
  }
}

.closeshare {
  position: absolute;
  right: 0;
}

.share {
.modal-dialog {

  .close {
    color: #fff !important;
  }
  .modal-header {
    border: none;
  }
  .SocialMediaShareButton--facebook {
    background-color: #3C5A99;
    padding: 10px 1rem;
    font-weight: bold;
  }

  .SocialMediaShareButton--twitter {
    background-color: #1DA1F2;
    padding: 10px 1rem;
    font-weight: bold;
  }
  padding: 8% 0 5%;
}
}

.modal-content {
  background: #202020;
  padding: 5%;
}

.videoclose  {
  position: absolute;
  top: 0.2em;
  z-index: 999;
  right: 0.6rem;
  span {
    color: white;
    text-shadow: 0px 0px 14px rgba(0, 0, 0, 1);
  }
}

.poster {
  position: absolute;
top: 0;
width: 100%;
}

.videoclose.close {
font-size: 3em;
}

.gallerySection {
  max-width: 1580px;
    margin: 0 1.5em;
}

@-webkit-keyframes fadeUp {
  0% {
         transform: scale(0.95);

     }

     100% {
         transform: scale(1);
     }
}
@keyframes fadeUp {
  0% {
         transform: scale(0.95);
         background-color: #fff;
         color: black;
         opacity: 0.5;

     }

     100% {
         transform: scale(1);
         background-color: #fff;
         color: black;
         opacity: 0.5;
     }
}

@-webkit-keyframes pulsing {
  0% {
         transform: scale(0.95);

     }

     70% {
         transform: scale(1);
     }

     100% {
         transform: scale(0.95);
         background-color: #fff;

     }
}
@keyframes pulsing {
  0% {
         transform: scale(0.95);
         background-color: #fff;
         color: black;
         opacity: 0.5;

     }

     70% {
         transform: scale(1);
         background-color: #fff;
         color: black;
         opacity: 0.5;
     }

     100% {
         transform: scale(0.95);
          background-color: #fff;
          color: black;
          opacity: 0.5;

     }
}

.nextPart {
  background-color: #fff;
  padding: 0.3em 0 0.3em;
  display: block;
  color: #fff;
  position: relative;
  transition: all .5s ease-in-out;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  height: 100%;
  animation: pulsing 2s infinite;
  .arrowBlock {
    position: relative;
    float: right;
    width: 10.6rem;
  }
  span {
    font-size: 0.9rem;
    display: block;
    line-height: 15px;
  }
  em {
    display: block;
    text-transform: uppercase;
    font-style: normal;
    line-height: 1.3em;
    font-weight: bold;
  }
  svg {
    font-size: 3em;
    position: absolute;
    top: 0;
    right: 0.4em;
  }
  a {
    color: #000;
    &:hover {
      text-decoration: none;
    }
  }
  &:hover {
    animation: fadeUp 0.5s;
    transform: scale(1.025);
    color: #000;
    text-decoration: none;
    opacity: 1;
    background-color: #fff;
  }
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}
.react-h5-audio-player {
  float: right;
  margin-right: 13px;
  .flex {
    display: inline-block !important;
    background-color: transparent !important;
    box-sizing: border-box;
    width: 24px !important;
    height: 24px !important;
    padding: 4px 0 0!important;
    .time, .progress-bar-wrapper {
      display: none !important;
    }
    .toggle-play-button {
      background-color: transparent !important;
      color: #666 !important;
      box-sizing: border-box;
      width: 0 !important;
      height: 0 !important;
      position: relative !important;
left: 0!important;
margin-left: 0!important;

    }
    .play-icon {
      border-left: 16px solid #666 !important;
      border-top: 8px solid transparent !important;
      border-bottom: 8px solid transparent !important;
      &:hover {
        border-left-color: #fff !important;
      }
    }
    .pause-icon {
      border-left: 6px solid #666 !important;
      width: 8px !important;
      height: 15px !important;
      box-shadow: black 2px 0px 0px 0px inset !important;
      background-color: #666 !important;
      border-radius: 2px;
      &:hover {
        border-color: #fff !important;
        background-color: #fff !important;

      }
    }

  }
}
//


.prevPart {
  background-color: rgba(60, 60, 60, 0.4);
  padding: 0.3em 1em 0.3em 4em;
  display: block;
  border: none;
  a {
    color: #fff;
    &:hover {
      color: #000;;
      text-decoration: none;
    }
  }
  color: #fff;
  position: relative;
  transition: all .5s ease-in-out;
  &:hover {
    transform: scale(1.025);
    color: #000;
    text-decoration: none;
    background-color: #fff;
    a {

        color: #000;;
        text-decoration: none;

    }
  }
  span {
    font-size: 0.9rem;
    display: block;
    line-height: 15px;
  }
  em {
    display: block;
    text-transform: uppercase;
    font-style: normal;
    line-height: 1.3em;
    font-weight: bold;
  }
  svg {
    font-size: 3em;
    position: absolute;
    top: 0.1em;
    left: 0.4em;
    }

}

.playButton {
  transition: all .5s ease-in-out;
  margin-top: 0.5em;
  /* background-color: #e5e5e5; */
  background-color: rgba(60, 60, 60, 0.4);
  border-width: 0;
  padding: 0.5em 2em;
  /* height: 1.2vw; */
  color: white;
  em {
    font-style: normal;
  }
  svg {
    margin-right: 7px;
  }
  &:hover {
  background-color: #fff;
  color: #000;
  transform: scale(1.025)
  }
}

.xs-visible {
  display: none !important;
}

.footer {
  background-color: #000;
  position: fixed;
  bottom: 0;
  z-index: 999;
  padding: 5px 1.5rem;
  .footerButton {
    padding: 0 10px;
    display: inline-block;
    color: #666;
    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
  .greySlash {
    display: inline-block;
    color: #666;
  }
  .shareFooter {
    position: absolute;
    right: 0;
  }
  .muteButton {
    button {
      background-color: transparent;
  border: none;
  color: #666;
  min-width: 25px
    }

  }
}

.footerSupporters {
  opacity: 1;
  position: relative;
bottom: 1.5rem;


}

.brandingImg {
  width: 75%;
  max-width: 200px;
margin: 0 auto;
display: block;
}


@media (max-width: 600px) {
  .xs-hidden {
    display: none !important;
  }
  .xs-visible {
    display: block !important;
  }
}



.galleryFixed {
  position: absolute;
  bottom: 1rem;
  width: 95%;
  }

@media (min-width: 576px) and (max-width: 900px){
    .changeBlock.container {
      max-width: 675px;
    }
}

@media (min-width: 600px) {
  .xs-hidden {
    display: block !important;
  }

  .sidebarIconToggle {
      top: 22px;
    }

  .textArea {
    font-size: 1rem;
    top: 19%;
    padding: 1.5rem 2rem ;
    h2 {
      font-size: 1.8rem;
      line-height: 2rem;
      padding-bottom: 1rem;
    }
    span {
      line-height: 1.4rem;
      margin-bottom: 1rem;
    }
  }
  .nextPart {
      border-left: none;
      right: 1.5em;
    padding: 0.3em 4.2em 0.4em 2.5em;
    position: absolute;
    top: 0.8em;
    height: initial;
    transition: all .5s ease-in-out;
    // span {
    //   font-size: 12px;
    // }
    svg {
      top: 0.1em;
    }

  }

  .prevPart {
    padding: 0.3em 2.5em 0.4em 4.2em;
    display: block;
    position: absolute;
    left: 4.5em;
    top: 0.8em;
  }
}

@media (min-width: 700px) {
  .xs-hidden {
    display: block !important;
  }

  .sidebarIconToggle {
      top: 22px;
    }

  .textArea {
    font-size: 1.2rem;
    top: 19%;
    h2 {
      font-size: 2.3rem;
      line-height: 2.3rem;
      padding-bottom: 1rem;
    }
    span {
      margin-bottom: 1rem;
      line-height: 1.3em;
    }

  }

  .prevPart {
    display: block;
    position: absolute;
    left: 4.5em;
    top: 0.8em;
  }

}

@media (min-width: 767px) {
  .homeOverlay {
    bottom: 18%;
    span {
      font-size: 4rem;
    }
    em {
      font-size: 1rem;
    }
  }
  .footerSupporters {
    opacity: 0;
    animation: fadein 5s;
        -moz-animation: fadein 5s;
        -webkit-animation: fadein 5s;
        -o-animation: fadein 5s;
        animation-delay: 8s;
        animation-fill-mode: forwards;
  }
  .start {
    margin-top: 2.5rem;
  }
  .footerSupporters {
    bottom: 0;
    max-width: 1200px;
    margin: 0 auto;

  }
}

@media (min-width: 800px) {
  .textArea {
    padding: 2rem 5%;
  }
}

@media (min-width: 850px) {
  .xs-hidden {
    display: block !important;
  }


  .sidebarIconToggle {
      top: 22px;
    }

  .textArea {
    text-shadow: 10px 10px 23px rgba(0, 0, 0, 1);
    font-size: 20px;
    left: 0;
    top: 19%;
    h2 {
      font-size: 2.5rem;
      line-height: 2.5rem;
      padding-bottom: 2rem;
    }
  }

  // .homeLogo {
  //   font-size: 5rem;
  //   em {
  //     font-size: 1.2rem;
  //   }
  // }
}
@media (min-width: 994px) and (max-width: 1250px) {
  .changeBlock {
    .col-lg-5 {
      flex: 0 0 90%;
      max-width: 90%;
      margin: 0 auto;
    }
    .col-lg-7 {
      flex: 0 0 90%;
      max-width: 650px;
      margin: 0 auto;
    }
  }
}

@media (min-width: 1000px) {
  // .homeLogo {
  //   font-size: 6rem;
  //   em {
  //     font-size: 1.5rem;
  //   }
  // }
  .changeBlock {
    padding: 9% 2%;
  }

}

@media (min-width: 1130px) {
  .gallerySection {
    position: absolute;
      bottom: 2rem;
      width: 95%;
    }
  .copyRight {
    margin-top: 8%;
  }

  .homeOverlay {
    bottom: 18%;
    span {
      font-size: 4.5rem;
    }
    em {
      font-size: 1.1rem;
    }
  }
  .start {
    margin-top: 2rem;
  }




  }

  @media (min-width: 1100px) {
    .react-player video {
       object-fit: cover;
      height: 100% !important;
      width: 100% !important;
      // margin-top: -5%;
  }
}




@media (min-width: 1245px) {
  // .slick-slider {
  //   margin: -2% 2em 1em;
  // }
  // .homeLogo {
  //   font-size: 7rem;
  //   em {
  //     font-size: 1.8rem;
  //   }
  // }
  .confineWidth {
    width: 90vw;
  }
  .confineWidth.home {
    width: auto;
  }
}

// }
